import neoAnalyse from "@/components/analyse";
// import { object } from "underscore";
import neoSwitch from "@/components/toggle-switch";
// import { getMonitoring } from "@/utils/functions";
import {get} from "lodash";
import {mapGetters} from "vuex";
import axios from "@/axios";
import adverseMixin from "@/mixins/adverseMixin";
import {EventBus} from "@/main.js";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
import {DateTime} from "luxon";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Loader from "@/components/loader";
const monitoringHistory = () => import("@/components/monitoring-history");

export default {
    components: {
        neoAnalyse,
        neoSwitch,
        monitoringHistory,
        "neo-loader": Loader,
    },
    mixins: [adverseMixin],
    props: {
        result: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Number,
            default: null,
        },
        index: {
            type: Number,
            default: null,
        },
        parentIndex: {
            type: Number,
            default: null,
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
        // getMonitoring: {
        //     type: Boolean,
        //     default: false,
        // },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // resultSave: false,
            monitoringHistoryIndex: null,
        };
    },
    methods: {
        // getMonitoring,
        get,
        parseNames(data) {
            return data.map((e) => e.name).join(", ");
        },
        parseTypes(data) {
            if (data?.trim()) return data?.split(",")?.map((e) => e.replace(/-/g, " ")?.replace(/None/g, "Others"));
            else return [];
        },
        parseDate(dt, format = "EEEE, dd LLLL yyyy") {
            if (dt) return DateTime.fromISO(dt).toFormat(format);
            else return "";
        },
        // expandResult(result, curr_index, index) {
        //     this.$emit("expandResult", result, curr_index, index);
        // },
        fetchSource(key) {
            if (key === "dowJones") {
                return this.result.api_all_data?.sources?.map((src) => `DJ-${src}`.toUpperCase());
            } else return [key];
        },
        async handleMonitoring(oldVersion) {
            // event.stopPropagation();
            let card = this.result;
            card.api_all_data.monitoring_status = !card.api_all_data.monitoring_status;
            let query;
            if (this.isAllRunQueries) {
                query = this.result.query;
            } else {
                query = this.getSelectedToolQuery;
            }
            try {
                await axios.post("/data-monitoring", {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.$route.params.id,
                    query: query,
                    tool: this.$route.params.toolName,
                    frequency: 7,
                    monitoring_type: "card",
                    inherit_product_settings: false,
                    inherit_product_id: "",
                    card_id: this.result.api_all_data.doc_id,
                    card_source: this.result.key,
                    status: card.api_all_data.monitoring_status ? "enabled" : "disabled",
                });
                this.$toast.success("Monitoring updated successfully !");
            } catch (error) {
                this.$toast.error("Something Went Wrong !");
            }
            EventBus.$emit("handleDone", {card: this.result, curr_index: card.curr_index, oldVersion});
            this.$forceUpdate();
        },
        getResultsID(index) {
            return `result-${index}`;
        },
        // changedResult(og_value, key, classes = false) {
        //     let result = {};
        //     let changeKey = this.get(this.result.api_all_data.change_history, key, []);
        //     if (changeKey.length) {
        //         result = this.getMonitoring(changeKey, og_value);
        //         return result;
        //     } else return { text: og_value };
        // },
        async handleDone({target}, oldVersion) {
            this.result.api_all_data.changes_viewed = !this.result.api_all_data.changes_viewed;
            EventBus.$emit("handleDone", {card: this.result, curr_index: this.result.curr_index, oldVersion});
            this.$forceUpdate();
            let sources = [];
            if (this.result.key === "dowJones") {
                sources = this.result.api_all_data?.sources?.map((src) => src.toLowerCase());
            } else sources = [this.result.key].map((src) => src.toLowerCase());
            let payload = [
                {
                    doc_id: this.result.api_all_data.monitoring_id,
                    sources: sources,
                    cascaded: false,
                },
            ];
            await axios.post("/data-monitoring/mark-monitered-data-visited", {
                card_info: payload,
                case_id: this.getCaseId,
                visited: target.checked,
            });
            this.updateComparisonReferences(this.result);
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },

        handleResultSaveData() {
            if (!this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex != this.result.api_all_data.doc_id) EventBus.$emit("handleResultSave", this.result);
        },
        getBackgroundStatus,

        showMonitoringHistory(index) {
            if (index !== this.monitoringHistoryIndex) this.monitoringHistoryIndex = index;
            else this.monitoringHistoryIndex = null;
        },

        updateComparisonReferences(data) {
            EventBus.$emit("updateMonitoringCascadingTimeline", {
                newData: data,
            });
        },
        getEntityType(result) {
            const type = result.entity_type;
            const key = result.key;
            if (key === "lexisBridger") {
                if (type === "Individual") return type;
                else return "Business";
            } else {
                return type;
                // if (type === "Individual" || ) return "Person";
                // else return "Company";
            }
        },
        getEntitySource(result) {
            if (result.entity_type.toLowerCase() === "person" || result.entity_type.toLowerCase() === "individual" || result.entity_type.toLowerCase() === "officer") return "name";
            else if (result.api_all_data.e_i && (result.api_all_data.e_i.toLowerCase() === "male" || result.api_all_data.e_i.toLowerCase() === "female")) return "name";
            else return "company_name";
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery", "getCaseId"]),
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        getResultClasses() {
            let classes = "";
            if (this.isOldVersionSelected) {
                if (this.result.curr_index === this.selected && this.isOldVersion) classes += " tw-bg-blue-50 tw-border-blue-600 hover:tw-border-blue-200 tw-shadow-md ";
                else classes += " tw-shadow tw-bg-white hover:tw-border-blue-200 hover:tw-bg-blue-50 ";
            } else {
                if (this.result.curr_index === this.selected && !this.isOldVersion) classes += " tw-bg-blue-50 tw-border-blue-600 hover:tw-border-blue-200 tw-shadow-md ";
                else classes += " tw-shadow tw-bg-white hover:tw-border-blue-200 hover:tw-bg-blue-50 ";
            }
            if (this.result?.api_all_data?.hasChanges) classes += "tw-border-green-500 tw-border-3 tw-shadow tw-bg-white hover:tw-bg-blue-50 hover:tw-border-blue-600";
            else classes += " tw-border ";
            // if (!this.result?.api_all_data?.history_count && !this.isOldVersion && this.isMonitoringTab)
            //     classes += ' tw-ml-2';
            if (this.isMonitoringTab) {
                classes += "tw-py-8";
            }
            return classes;
        },
        getSavedClasses() {
            let classes = "";
            if (this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex === this.result.api_all_data.doc_id) classes += " tw-opacity-40 tw-cursor-not-allowed ";
            if (this.result.api_all_data.saved) classes += " tw-text-blue-700 tw-font-semibold  tw-text-xs tw-bg-blue-200 ";
            else classes += " tw-bg-blue-200 tw-text-blue-800 ";
            return classes;
        },
    },
};
