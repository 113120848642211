import NeoNewsCard from "@/components/news-card";
import NeoAnalyse from "@/components/analyse";
import {getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import {EventBus} from "@/main.js";
import neoSwitch from "@/components/toggle-switch";
import axios from "@/axios";
import {mapGetters} from "vuex";
import {DateTime} from "luxon";

export default {
    name: "result-list",
    components: {NeoNewsCard, NeoAnalyse, neoSwitch},
    props: {
        result: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Number,
            default: null,
        },
        index: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery"]),
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
    },
    mounted() {},
    methods: {
        getBackgroundStatus,
        getResultsID,
        async handleMonitoring(idx) {
            this.result.monitoring_status = !this.result.monitoring_status;
            EventBus.$emit("handleDone", {card: this.result, index: this.index});
            this.$forceUpdate();
            await axios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: this.result.doc_id,
                card_source: "person-identifier-tool",
                status: this.result.monitoring_status ? "enabled" : "disabled",
            });
        },
        async handleDone({target}, idx) {
            this.result.changes_viewed = !this.result.changes_viewed;
            EventBus.$emit("handleDone", {card: this.result, index: this.index});
            this.$forceUpdate();
            let sources = ["person-identifier-tool"];
            await axios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: this.result.monitoring_id,
                visited: target.checked,
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
