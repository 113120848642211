import { render, staticRenderFns } from "./person-identifier-list.html?vue&type=template&id=37d3bc8b&scoped=true&"
import script from "./person-identifier-list.js?vue&type=script&lang=js&"
export * from "./person-identifier-list.js?vue&type=script&lang=js&"
import style0 from "./person-identifier-list.scss?vue&type=style&index=0&id=37d3bc8b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d3bc8b",
  null
  
)

export default component.exports