<template>
    <section class="news-card tw-w-full" :id="getCardID">
        <nlp-analysis-highlighting ref="nlpAnalysisHighlighting" class="tw-relative tw-p-0" :use-server-highlight="false">
            <div class="tw-bg-white tw-shadow-md hover:tw-border-blue-200 hover:tw-shadow-lg tw-transition-all tw-border tw-border-solid tw-border-gray-200 tw-rounded-md tw-flex tw-justify-between tw-overflow-hidden tw-cursor-pointer tw-relative tw-z-10" :class="getResultClasses" @click="expandUniCourtCard">
                <div v-if="isOldVersion" class="tw-px-2"></div>
                <div class="tw-w-full tw-flex-rows tw-py-2 tw-px-4 tw-text-sm">
                    <!-- STATUS RESULT MONITORING -->
                    <div v-if="isMonitoringTab" class="tw-flex tw-absolute tw-top-0 tw-left-0 tw-mr-2 tw-items-center tw-py-1 tw-text-xs tw-px-3 tw-rounded-br-lg tw-capitalize" :class="getBackgroundStatus(result.api_all_data.monitoring_result_status)">
                        <span style="line-height: 1.05">
                            {{ result.api_all_data.monitoring_result_status }}
                        </span>
                    </div>
                    <div class="tw-flex tw-h-10 tw-items-center tw-justify-between tw-py-2 tw-w-full" disabled>
                        <div class="tw-flex tw-text-lg tw-text-brand tw-w-4/5 tw-line-clamp-1" :title="getPartyOrSnippets">
                            {{ getPartyOrSnippets }}
                        </div>
                        <div class="tw-flex tw-text-right">
                            <div class="tw-ml-auto tw-flex tw-gap-2 tw-items-center tw-flex-shrink-0" @click.stop>
                                <!-- <comments class="searchIcon tw-cursor-pointer-not-allowed tw-mt-1.5" v-tippy content="Comments"> </comments> -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-not-allowed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                </svg>
                                <span class="tw-inline-flex tw-cursor-not-allowed tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-blue-100 tw-text-blue-800 tw-capitalize" id="full-screen-view-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" v-tippy content="View" stroke="currentColor" class="tw-w-4 tw-h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                                    </svg>
                                    <!-- <div class="tw-ml-2">View</div>   -->
                                </span>
                                <span id="print-button" class="tw-cursor-not-allowed tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-blue-100 tw-text-blue-800 tw-capitalize">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" v-tippy content="Print" class="bi bi-printer-fill" viewBox="0 0 16 16">
                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                    </svg>
                                    <!-- <div class="tw-mr-2">Print</div> -->
                                    <!-- <neo-loader class="" :loading="isPrintLoader" /> -->
                                </span>

                                <div class="print-hidden tw-flex tw-items-center">
                                    <font-awesome-icon icon="save" class="saveIcon print-hidden tw-outline-none tw-h-6 tw-w-6 tw-cursor-not-allowed tw-text-gray-600 hover:tw-text-gray-800" v-tippy :content="'save'" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-flex tw-items-center tw-py-2 tw-text-sm tw-w-full">
                        <div class="tw-text-yellow-600" v-if="result.jurisdiction">
                            <span class="tw-bg-yellow-100 tw-px-1">{{ result.jurisdiction }}</span>
                        </div>
                        <div class="tw-pl-6 tw-text-red-500 tw-px-1" v-if="result.caseType">
                            <span class="tw-bg-red-100 tw-pl-1">{{ result.caseType.trim() }}</span>
                            <span class="tw-bg-red-100 tw-pr-1" v-if="result.caseTypeGroup">{{ " - " + result.caseTypeGroup.trim() }}</span>
                        </div>
                    </div>
                    <div class="tw-flex tw-h-15 tw-justify-between tw-items-center tw-py-2 tw-w-full">
                        <!-- <div class="tw-flex ">main content</div> -->
                        <div class="tw-flex">
                            <div class="tw-text-brand">Case Number</div>
                            <div class="tw-px-2">{{ result.caseNumber || "-" }}</div>
                            <div class="tw-text-brand">Filling Date</div>
                            <div class="tw-px-2">{{ getDate || "-" }}</div>
                            <div class="tw-text-brand">Case status</div>
                            <div class="tw-pl-2">{{ result.caseStatus || "-" }}</div>
                        </div>
                    </div>
                    <!-- no need to show on initial card -->
                    <!-- <div class="tw-flex-rows py-4 tw-gap-2">
                        <div class="tw-flex tw-py-2" v-for="(item, key) in partyInfo" :key="key">
                            <div class="tw-text-brand">{{ key }} -</div>
                            <div class="tw-px-1">{{ makeDataToShow(partyInfo[key], key) }}</div>
                           
                        </div>
                    </div> -->
                </div>
            </div>
        </nlp-analysis-highlighting>
    </section>
</template>

<script>
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";
import {EventBus} from "@/main.js";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
// import Comments from "@/components/comments";
import {DateTime} from "luxon";

export default {
    name: "uni-court-card",
    components: {
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
        // Comments,
    },
    props: {
        result: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            default: null,
        },
        parentIndex: {
            type: Number,
            default: null,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Number,
            default: null,
        },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMonitoringTab: false,
            footerOptions: [
                {name: "Appliant", value: "appliant"},
                {name: "Not yet classsified", value: "notYetClassified"},
                {name: "Respondent", value: "respondent"},
                {name: "Defendant", value: "defendant"},
            ],
            partyInfo: null,
        };
    },
    computed: {
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },
        getResultClasses() {
            let classes = "";
            if (this.index === this.selected) {
                classes = "tw-bg-blue-50 tw-border-blue-600 hover:tw-bg-blue-50";
            }

            return classes;
        },
        getDate() {
            if (this.result.filingDate) {
                let date = new Date(this.result.filingDate).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "";
            // getFormattedTime(this.getSelectedToolQuery?.query?.last_runtime);
        },
        getPartyOrSnippets() {
            if ((this.result.key === "judyRecords" && this.result.api_all_data.data.is_details_scraped) || this.result.key === "unicourt") {
                return this.result.party;
            } else {
                return this.result.api_all_data.data.snippet;
            }
        },
    },
    methods: {
        getBackgroundStatus,
        // getPartyInfo() {
        //     const partyInfo = this.result.api_all_data?.party_info;
        //     if (partyInfo?.length < 0) {
        //         let formatData = {};
        //         partyInfo.forEach((item) => {
        //             let hasKey = [item.type] in formatData;
        //             if (!hasKey) {
        //                 formatData[item.type] = null;
        //             }
        //         });
        //         for (const i in formatData) {
        //             let parties = [];
        //             partyInfo.forEach((item) => {
        //                 if (item.type === i) {
        //                     parties.push(item.name);
        //                 }
        //             });
        //             formatData[i] = parties;
        //         }
        //         this.partyInfo = formatData;
        //     }
        // },
        expandUniCourtCard() {
            EventBus.$emit("openShowMorePanel", {
                result: this.result,
                isOldVersion: this.isOldVersion,
                cardIndex: this.index,
            });
            // setTimeout(() => {
            //     document.getElementById(`result-card-${this.index}`)?.scrollIntoView();
            // }, 1000);
        },
        // makeDataToShow(item, key) {
        //     if (key === "Defendant" || key === "Plaintiff") {
        //         let count = 0;
        //         const regex = /\bDoe, (John|Jehn) \d+\b/;
        //         item.forEach((item) => {
        //             if (regex.test(item)) {
        //                 count++;
        //             }
        //         });
        //         return "Doe 1 through " + count;
        //     } else {
        //         item.toString();
        //     }
        // },
    },
};
</script>
